define('tooltip',['bootstrap'], function($){
  'use strict';

  var
    tooltipsData = {
      'general': {
        'header-search': 'Only legislation that has been published in HTML format is searchable',
        'header-search-ga': 'Níl ach leaganacha hipearnaisc (leaganacha HTML) den reachtaíocht inchuardaithe ón leathanach cuardaigh ar an eISB.'
      },
      'homepage': {
        'top-right-title': 'This is the most recently published legislation in PDF',
        'top-right-title-ga': 'Seo é an Reachtaíocht is déanaí a foilsíodh in PDF'
      },
      'advanced-search': {
        'form-title-only': 'Use double quotation marks for an exact phrase: "child care act" Always use caps for AND, OR, NOT: child AND welfare',
        'form-title-only-ga': 'Bain úsáid as comharthaí dúbailte athfhriotail i gcomhair frása beacht: "acht um chúram leanaí".  Bain úsáid as ceannlitreacha i gcomhair AGUS, NÓ, NÍ: leanbh AGUS leas',
        'form-title-text': 'Use double quotation marks for an exact phrase: "commits an offence" Always use caps for AND, OR, NOT: child OR minor',
        'form-title-text-ga': 'Bain úsáid as comharthaí dúbailte athfhriotail i gcomhair frása beacht: coir a dhéanamh".  Bain úsáid as ceannlitreacha i gcomhair AGUS, NÓ, NÍ: leanbh NÓ mionaoiseach',
        'form-long-title': 'Use double quotation marks for an exact phrase: "child protection" Always use caps for AND, OR, NOT: children AND protection',
        'form-long-title-ga': 'Bain úsáid as comharthaí dúbailte athfhriotail i gcomhair frása beacht: "cosaint leanaí".  Bain úsáid as ceannlitreacha i gcomhair AGUS, NÓ, NÍ: leanbh AGUS cosaint',
        'form-act-no': 'Enter number only to search by number.',
        'form-act-no-ga': 'Enter number only to search by number.',
        'form-act-section-no': 'Use with the Act Number search. Enter section number to search for a specific section of an Act.',
        'form-act-section-no-ga': 'Úsáid leis an gcuardach Uimhir Achta. Iontráil uimhir an ailt chun alt ar leith d’Acht a chuardach.',
        'form-years-range': 'Specify years or a range to limit your search to Acts and SIs within those years only.',
        'form-years-range-ga': 'Sonraigh blianta nó raon chun do chuardach a theorannú d’Achtanna agus IRí laistigh de na blianta sin amháin.'
      },
      'act-page': {
        'amendments-post2004': 'Information on how this Act has been affected, when it was brought into force and SIs made under it.',
        'revisedAct-post2004': 'Link to the Revised Act (administrative consolidation) as produced by the Law Reform Commission.',
        'revisedAct-post2004-ga': 'Achtanna Athbhreithnithe. Link to the Revised Act (administrative consolidation) as produced by the Law Reform Commission.',
        'amendments-pre2004': 'Information on how this Act has been affected. See the FAQ for information on how to find out if an Act is in force.',
        'amendments-pre2004-ga': 'Faisnéis faoin tionchar ar an Acht seo. Féach ar na CCanna le haghaidh faisnéise faoi conas a fháil amach má tá Acht i bhfeidhm. ',
        'revisedAct-pre2004': 'Unofficial version of the Act as amended. It will be displayed here if available.',
        'amendments-pre1922': 'Information on how Act has been affected.',
        'amendments-si':'See how this SI has been affected. Only amendments made by Acts and SIs from 1988 onwards are included.',
        'revisedAct-button': 'Unofficial version of the Act as amended. It will be displayed here if available.',
        'amendments-button': 'Information on how this Act has been affected. See the FAQ for information on how to find out if an Act is in force.'
      }
    },

    $tooltips = $('[data-tooltip-id]'),

    getTooltipIds = function (data){
      var ids = (data || '').split('_');
      return {
        pageId: ids[0],
        tooltipId: ids[1]
      };
    };

  function bindTooltips(data){
    $tooltips.each(function(index, element){
      var
        $element = $(element),
        ids = getTooltipIds($element.attr('data-tooltip-id')),
        options = {
          title: data[ids.pageId] ? data[ids.pageId][ids.tooltipId] : ''
        };

      $element.tooltip(options);

    });
  }

  // First we load the remote tooltip data
  bindTooltips(tooltipsData);

  $tooltips.on('click', function(event){
    event.stopPropagation();
    event.preventDefault();
  });

  return {};
});

