define('datatable-plugins/datatable-tooltip',['jquery', 'datatables'], function($){
  'use strict';
  
  // Datatable - Tooltip + mouse follow
  $('.datatable tr th').hover(function(){
      var $dataTip = $(this).data('tip');
      if($dataTip){
        $(this).append('<span class="tip">' + $dataTip + '</span>');
        var $closestHead = $(this).parents('thead'),
            $offsetPos = $closestHead.offset();
        $closestHead.on('mousemove', function(e){
          $(this)
            .find('.tip')
            .css({
              left: e.pageX - $offsetPos.left,
              top: e.pageY - $offsetPos.top
            });
        });
      }
  },function(){
    $('.tip').remove();
  });

});
