define('scrollTop',['jquery'], function($){
  'use strict';

  var
    options = {
      scrollOffset: 475, // When in the scroll the hook should appear
      transitionSpeed: 500 // Speed of Scroll
    },
    hasClass,
    $body = $('html, body'),
    $window = $(window),
    $riseHook = $('.js-rise-hook');

  $window.scroll(function(){

    hasClass = $riseHook.hasClass('show-element');

    // If after scroll offset
    if( !hasClass && $window.scrollTop() > options.scrollOffset ) {
      $riseHook.addClass('show-element');
    }
    // If before scroll offset
    else if ( hasClass && $window.scrollTop() < options.scrollOffset ) {
      $riseHook.removeClass('show-element');
    }

  });

  $riseHook.on('click', function(){
    $body.animate({
      scrollTop: '0px'
    }, options.transitionSpeed);
  });
  
});

