define('print',['jquery'], function($){
  'use strict';

  // Checks the presence of the print command on the querystring. This will
  // allow to automatically trigger the print in the Full Acts pages, when we
  // find such flag in the URL.
  var
    printOnloadExp = /printonload=true/ig,
    locationSearch = window.location.search;

  if (locationSearch.match(printOnloadExp)){
    window.print();
  }

  // Attaches default print functionality to the print section btn
  $('.btn-print-section').on('click', function (event){
    event.stopPropagation();
    window.print();
  });

});

