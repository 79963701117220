define('legislation-pdf',['jquery', 'xmlToJSON', 'handlebars'], function($, xmlToJSON, handlebars){
  'use strict';

  function recentLegislation(data){
    var
      templateSource = {
        nodes: data.tabs[0].tab
      },
      // Compiles the HTML template for tabs
      tabsTemplate = handlebars.compile(
        $('#hb-recent-legislation-tabs').html() || ''
      ),
      // Compiles the HTML template for tabs content
      tabsContentTemplate = handlebars.compile(
        $('#hb-recent-legislation-tabs-content').html() || ''
      ),
      // Renders the template html for tabs
      tabsHTML = tabsTemplate(templateSource),
      // Renders the template html for tabs content
      tabsContentHTML = tabsContentTemplate(templateSource);

    // Appends the rendered templates HTML to the proper targets
    $('.recent-legislation .nav-tabs').append(tabsHTML);
    $('.recent-legislation .tab-content').append(tabsContentHTML);
  }

  $.ajax({
    type: 'GET',
    url: '/static/assets/language/en_IE/index-recentLegislation.xml',
    dataType: 'xml',
    success: function(data) {
      var dataJson = xmlToJSON.parseXML(data.documentElement, {
        xmlns: false
      });
      recentLegislation(dataJson);
    },
    error: function (xhr, ajaxOptions, thrownError) {
      console.log(xhr.status);
      console.log(thrownError);
    }
  });
});

