define('faq',['jquery'], function($){
  'use strict';


  function scrollTo(target) {
    $('html, body').animate({ scrollTop: $(target).offset().top }, 500);
    return false;
  }


  // Nav menu link click: "-" & "+" icon toggle
  $('.faq .side-block .arrowed-list a').on('click', function(){
    var blockId = $(this).attr('href');
    blockId = blockId.slice(-1);
    $('a[data-panel=' + blockId + ']').toggleClass('collapsed');
    scrollTo('a[data-panel=' + blockId + ']');
  });


  // First panel unique link opening
  $('#faqLink-trackingAmendment').on('click', function(){
    var $panelLink = $('a[data-panel="2"]');

    if ($panelLink.hasClass('collapsed')){
      $('#js-panel-2').collapse('show');
      $panelLink.removeClass('collapsed');

      scrollTo('#js-panel-2');

      setTimeout(function(){
        $('#js-panel-js-panel-2-0').collapse('show');
        $('a[data-panel="2"] > a').removeClass('collapsed');
      },350);
    }
    else {
      $('#js-panel-2').collapse('hide');
      $panelLink.addClass('collapsed');
      $('#js-panel-js-panel-2-0').collapse('hide');
      $('a[data-panel="2"] > a').addClass('collapsed');
    }
  });


  // Links from others page which have to open a panel on page load.
  // -- Advanced-search.html - "More Search help and tips" link
  // -- Index.html - "track amendments to legislation" link
  var faqLocalStorage = localStorage.getItem('faqPanel'),
      faqLocalStorageLink = localStorage.getItem('faqPanelId'),
      faqTarget;

  $('.faq-panel-link').on('click', function(e){
    e.preventDefault();
    faqTarget = $(this).data('faq');
    localStorage.setItem('faqPanel', 'true');
    localStorage.setItem('faqPanelId', faqTarget);

    window.location = $(this).attr('href');
  });

  $(document).ready(function(){
    if(faqLocalStorage === 'true'){
      setTimeout(function(){
        $('#js-panel-' + faqLocalStorageLink).collapse('show');
        $('a[data-panel="' + faqLocalStorageLink + '"]').removeClass('collapsed');
      },200); 
      localStorage.removeItem('faqPanel');
      localStorage.removeItem('faqPanelId');
    }
  });
});

