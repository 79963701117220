// RequireJS configuration
(function main(require){
  'use strict';

  var
    BOWER_COMPONENTS = '/bower_components/',
    jQueryDeps =  {
      deps: ['jquery'],
      exports: '$'
    },
    config = {
      baseUrl: 'static/javascript/',

      paths: {
        'jquery': BOWER_COMPONENTS + 'jquery/jquery',
        'js-cookie': BOWER_COMPONENTS + 'js-cookie/src/js.cookie',
        'bootstrap': BOWER_COMPONENTS + 'bootstrap/dist/js/bootstrap',
        'datatables': (
          BOWER_COMPONENTS + 'DataTables/media/js/jquery.dataTables'
        ),
        'select2': BOWER_COMPONENTS + 'select2/select2',
        'handlebars': BOWER_COMPONENTS + 'handlebars/handlebars.amd',
        'xmlToJSON': BOWER_COMPONENTS + 'xmlToJSON.js/lib/xmlToJSON',
        'match': '/static/lib/match.min'
      },

      shim: {
        bootstrap: jQueryDeps,
        datatables: jQueryDeps,
        select2: jQueryDeps,
        xmlToJSON: {
          exports: 'xmlToJSON'
        },
        match: {
          exports: 'Match'
        }
      }
    };

  require.config(config);
  require([
    'acts-sticky-header',
    'acts-year-select',
    'cookies',
    'datatable-init',
    'dropdown',
    'faq',
    'feedback',
    'home-image',
    'legislation-pdf',
    'navbar',
    'scrollTop',
    'search',
    'search-refine',
    'social-share',
    'tooltip',
    'print',
    'toggle'
  ]);

}(require));

define("main", function(){});

