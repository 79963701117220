define('acts-year-select',['jquery','select2'], function($){
  'use strict';
  var
    $field,
    year,
    selectedYear,
    datatablesYearFilter = '#js-datatables-year-filter',
    fieldOutput = '';

  function selectRangeFactory(range, field){
    $field = $(field);

    if(!$field.length){
      return;
    }

    for(year = range[1]; year >= range[0]; year--){
      fieldOutput += '<option value="' + year + '">';
      fieldOutput += year;
      fieldOutput += '</option>';
    }

    $field.append(fieldOutput);
  }

  // If select exists
  if($(datatablesYearFilter).length){
    selectRangeFactory([1922, new Date().getFullYear()], datatablesYearFilter);
    $(datatablesYearFilter).select2();
  }

  // Change page location on change value
  $(datatablesYearFilter + ', .year-filter').on('change',function(){
    selectedYear = this.options[this.selectedIndex].value;
    if(selectedYear){
      if ($(this).hasClass('statutory')){
    	  window.location = '/' + selectedYear + '/statutory.html';
      }else{
    	  window.location = '/' + selectedYear + '/' + selectedYear + '.html';
      }
    }
  });
});

