define('datatable-init',[
    'datatable-plugins/datatable-extended',
    'datatable-plugins/datatable-filters',
    'datatable-plugins/datatable-tooltip',
    'datatable-plugins/datatable-stick-menu'
  ],
  function($, datatableFilters) {
    'use strict';
    var order = 1;
    var singleColumn = $('.act-general').data('single-column'),
      datatableColumns = {
        first: singleColumn ? '10%' : '15%',
        second: singleColumn ? '85%' : '80%'
      },
      instantiateDatatable = function(index, element) {
    	var $datatable;
    	var is_sortable = true;
    	if ($(element).attr('id') === 'constitutional-amendments-dtb'){
    		is_sortable = false;
    	}
        if ($(element).attr('id') === 'public-acts-dtb' || $(element).attr('id') === 'statutory-instruments-dtb') {
          if ($(element).attr('id') === 'statutory-instruments-dtb') {
            order = 0;
          }
          $datatable = $(element).DataTable({
            searchCols: [
              { regex: true },
              { regex: true },
            ],
            paging: false,
            language: {
              search: 'Refine by',
              searchPlaceholder: 'First letter(s) in title'
            },
            order: [
              [order, 'asc']
            ],
            aoColumns: [{
              sWidth: datatableColumns.first
            }, {
              sWidth: datatableColumns.second
            }, {
              sWidth: '5%',
              sClass: 'action-cell'
            }],
            columnDefs: [{
              type: 'numbered-only',
              targets: 0
            }]
          });
        } else {
          $datatable = $(element).DataTable({
            searchCols: [
              { regex: true },
            ],
          	bSort: is_sortable,
            bFilter: false,
            paging: false,
            language: {
              search: 'Refine by',
              searchPlaceholder: 'First letter(s) in title'
            },
            order: [
              [order, 'asc']
            ],
            aoColumns: [{
              sWidth: datatableColumns.second
            }, {
              sWidth: '5%',
              sClass: 'action-cell'
            }],
          });
        }

        // Initializes the custom filters for the datatable
        datatableFilters.initialize($datatable, $(element).attr('id'));
      };
    $('.datatable').each(function(i, element) {
      instantiateDatatable(i, element);
    });
  });

