define('datatable-plugins/datatable-extended',['jquery', 'datatables'], function($){
  'use strict';

  $.extend($.fn.dataTableExt.oSort, {
    'numbered-only-asc': function (one, two) {
      var
        output = 0;

      one = parseFloat(one.replace(/[^\d\+]/g,''));
      two = parseFloat(two.replace(/[^\d\+]/g,''));

      if(one > two){
        output = 1;
      }else if(two > one){
        output = -1;
      }
      return output;
    },
    'numbered-only-desc': function (one, two) {
      var
        output = 0;

      one = parseFloat(one.replace(/[^\d\+]/g,''));
      two = parseFloat(two.replace(/[^\d\+]/g,''));

      if(one > two){
        output = -1;
      }else if(two > one){
        output = 1;
      }
      return output;
    }
  });

  return $;

});

