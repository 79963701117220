define('social-share',['jquery'], function($){
  'use strict';

  // Chainlink - Input in tooltip to copy the link in clipboard.
  $('#social-share-url-input')
    .val(window.location.href)
    .on('click', function(){
      this.setSelectionRange(0, this.value.length);
    });
});

