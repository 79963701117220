define('search-module',['jquery', 'match'], function($, Match){
  'use strict';

  var searchModule = {};

  function registerNavigationEvents(match, matches, startMatch){
    var matchNumber = startMatch,
      addClass = searchModule.utils.addClass,
      removeClass = searchModule.utils.removeClass,
      btnWordSearchPrev = document.getElementById('word-search-prev'),
      btnWordSearchNext = document.getElementById('word-search-next'),
      navigateNext = function navigateNext(){
        if (matchNumber + 1 <= matches){
          matchNumber = match.nextMatch();
          // Enables the prev button if necessary
          removeClass(btnWordSearchPrev, 'disabled');
        }

        if (matchNumber === matches){
          addClass(btnWordSearchNext, 'disabled');
        }
      },
      navigatePrev = function navigatePrev(){
        if (matchNumber - 1 > 0){
          matchNumber = match.prevMatch();
          // Enables the next button if necessary
          removeClass(btnWordSearchNext, 'disabled');
        }

        if (matchNumber === 1){
          addClass(btnWordSearchPrev, 'disabled');
        }
      };

    $(document).on('keydown', function onKeypress(event){
      var RIGHT_ARROW_CODE = 39,
        LEFT_ARROW_CODE = 37;

      switch (event.keyCode) {
        case RIGHT_ARROW_CODE:
          navigateNext();
          break;
        case LEFT_ARROW_CODE:
          navigatePrev();
      }
    });

    // Ensure buttons are enabled if next/previous result exists
    if (matchNumber + 1 <= matches){
      removeClass(btnWordSearchNext, 'disabled');
    }
    if (matchNumber - 1 > 0){
      removeClass(btnWordSearchPrev, 'disabled');
    }

    $(btnWordSearchPrev).on('click', navigatePrev);
    $(btnWordSearchNext).on('click', navigateNext);
  }

  searchModule.utils = {
    // Ensures that characters interpreted as special ones in compiled
    // regular expressions will be escaped and treated as string
    // literals.
    escapeRegExp: function escapeRegExp(input){
      return input.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
    },

    // Replaces the unwanted characters that may be received in search terms
    sanitizeParam: function sanitizeParam(word){
      return (word || '').replace(
        /\+/g,
        ' '
      ).replace(
        // Removes all quotes
        /\"/g,
        ''
      );
    },

    getRegexp: function getRegexp(keyword){
      return new RegExp(this.escapeRegExp(keyword), 'gi');
    },

    // Get a parameter from a string
    getParam: function getParam(key, url){
      /* global unescape */
      // The regex will extract 'myvalue' from 'parameter=myvalue'
      var equation = this.escapeRegExp(key) + '=.*?(?=\\&|$)',
        result = url.match(new RegExp(equation), 'g');

      return result ?
        searchModule.utils.sanitizeParam(
          decodeURIComponent(unescape(result[0]).replace(key + '=', ''))
        ) : null;
    },

    // Replace a parameter in a string
    replaceParam: function replaceParam(key, newval, url){
      var value = this.getParam(key, url);
      // Ensure parameter exists, if not then add it and replace value
      return (value !== null) ?
        url.replace(
          key + '=' + value,
          key + '=' + newval) :
        url + '&' + key + '=' + newval;
    },

    addClass: function addClass(el, className){
      // Adds the class if not already present
      if (el && el.className.indexOf(className) === -1){
        el.className += ' ' + className;
      }
    },

    removeClass: function removeClass(el, className){
      // Runs remove routine if the class is present
      if (el && el.className.indexOf(className) >= 0){
        el.className = el.className.replace(
          searchModule.utils.getRegexp(' ' +
          className),
          ''
        );
      }
    },

    appendCustomStyles: function appendCustomStyles(FOCUS_CLASS){
      var style = document.createElement('style'),
        styleString = (
        '.' + FOCUS_CLASS +
        '{ background-color: #F5B87F; color: inherit; }' +
        ' body { margin-bottom: 150px; }'
        );

      style.setAttribute('type', 'text/css');

      if (document.head){
        document.head.appendChild(style);
        style.innerHTML = styleString;
      }
      else {
        // Handles IE8 modes
        document.getElementsByTagName('head')[0].appendChild(
          style);
        style.styleSheet.cssText = styleString;
      }
    }

  };

  searchModule.searchBar = function searchBar(){};

  searchModule.searchHighlight = function searchHighlight(){
    var i,
      nodes,
      keywords,
      sectionKey,
      paramValue,
      matches = 0,
      sectionElements,
      queryContainsKey,
      // List of parameters identifying terms that should be searched
      // document wise (not in a specific section)
      freeSearchParams = [
        'q_phrase',
        'q',
        'highlight_term'
      ],
      // Maps query string parameter names to the HTML classes that
      // identifies the specific section of the document where to
      // search.
      sectionClasses = {
        'q_search_long_title': 'longtitle',
        'q_search_short_title': 'shorttitle',
        'q_sidenote': 'sidehead'
      },
      // The following specifies in which order the search keys should
      // be looked up, so to generate search results that are
      // navigated in the desired order. Swap the order of these
      // elements to obtain search results in a different order.
      searchOrder = [
        'q_search_short_title',
        'q_search_long_title',
        'q_sidenote'
      ],
      utils = searchModule.utils,
      isSearchingBySection = false,
      // Uses the Match library
      // Sets up the necessary options for the highlight
      match = new Match({
        HIGHLIGHT_CLASS: 'search-highlight'
      }),
      searchString = window.location.search,
      isWholeWords = Boolean(utils.getParam('whole', searchString));

    searchModule.utils.appendCustomStyles('search-focus');

    // First let's clear previously done search if any
    match.clear();

    i = 0;
    while (i < searchOrder.length) {
      sectionKey = searchOrder[i];

      // We test that the key is among the query string parameters
      queryContainsKey = (new RegExp(sectionKey, 'gi')).test(searchString);

      if (queryContainsKey){

        // We test that the key exists in the map of section classes
        if (sectionClasses.hasOwnProperty(sectionKey)){
          isSearchingBySection = true;
          nodes = sectionElements = null;

          if (sectionClasses[sectionKey]){
            // We select the element by class name
            sectionElements = document.querySelectorAll(
              '.' + sectionClasses[sectionKey]
            );

            if (sectionElements.length){
              nodes = sectionElements;
            }
          }
        }
        else {
          console.log('Cannot find selector for', sectionKey);
          // Will jump to next iteration
          continue;
        }

        if (nodes){
          keywords = utils.getParam(sectionKey, searchString).split(',');
          matches += match.search(keywords, nodes, {
            wholeWords: isWholeWords
          });
        }
        else {
          console.log('No section elements selected in DOM for', sectionKey);
        }
      }
      i += 1;
    }

    if (!isSearchingBySection){
      nodes = document.getElementsByTagName('td');
      if (!nodes){
        return;
      }

      i = 0;
      keywords = [];
      // Builds array of keywords to be searched anywhere in the doc
      while (i < freeSearchParams.length) {
        paramValue = utils.getParam(freeSearchParams[i], searchString);

        if (paramValue){
          // We want to search this term only if we have not already added to
          // the keywords, to avoid double highlights of the same terms given to
          // different search parameters
          if (keywords.indexOf(paramValue) === -1){
            keywords = keywords.concat(
              paramValue.split(',')
            );
          }
        }

        i++;
      }

      matches += match.search(keywords, nodes, {
        wholeWords: isWholeWords
      });
    }

    // Navigates the result matches
    if (matches){
      registerNavigationEvents(match, matches, 1);
      // Prevents the browser from maintaining the scroll position on
      // page refresh
      window.setTimeout(function(){
        match.nextMatch();
      }, 250);
    }

    //
    // Registers event handlers to handle clear
    //
  };

  searchModule.searchBar();
  searchModule.searchHighlight();
});

