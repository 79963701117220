define('acts-sticky-header',['jquery'], function($){
  'use strict';

  var $window = $(window),
      winPosition,
      $element = $('#js-stick-window'),
      $mainContent = $('.main-content'),
      elPosition;

  if($mainContent.hasClass('act-single') || $mainContent.hasClass('act-general')){
    if(!$element.length){
      return;
    }

    // Wait for cookie bar to hide/show
    setTimeout(function(){
      elPosition = $element.offset();
    }, 500);

    $('.js-stick-window-placeholder').css({
      height: $element.height()
    });

    $window.scroll(function(){

      if(!elPosition){
        return;
      }

      winPosition = $window.scrollTop();
      $mainContent[
        (elPosition.top < winPosition) ? 'addClass' : 'removeClass'
      ]('js-sticky-header');

    });

  }

});
