define('datatable-plugins/datatable-filters',['jquery', 'datatables'], function($) {
  'use strict';

  function initialize(datatable, tableID) {

    var
      number_placeholder = 'Number of Act',
      wrapSearchWithRegExp = function(keyword) {
        if (!keyword) {
          return '';
        }

        return '^' + keyword;
      };

    if (tableID === 'public-acts-dtb' || tableID ===
      'statutory-instruments-dtb') {
      if (tableID === 'statutory-instruments-dtb') {
        number_placeholder = 'Number of SI';
      }
      // Generate the 'Search by Number' input
      $('#' + tableID + '_wrapper').prepend(
        '<div id="DataTables_TableNumber_' + tableID + '_filter" ' +
        'data-tip="Search by number" class="dataTables_filter">' +
        '<label>Refine by' +
        '<input type="search" class="dataTables_filterNumber" data-table="' +
        tableID + '" placeholder="' + number_placeholder + '" />' +
        '<i class="fa fa-times numberSearchX"></i>' +
        '</label></div>');
    }
    $('#' + tableID + '_filter label').append(
      '<i class="fa fa-times titleSearchX"></i>');
    $('#' + tableID + '_filter label input').attr('data-table', tableID);
    $('#' + tableID + '_filter label input').attr('id', 'titleSearch');

    $('#public-acts-dtb_filter input').addClass('titleSearch');
    $('#statutory-instruments-dtb_filter input').addClass('titleSearch');
    $('#constitutional-amendments-dtb_filter input').addClass('titleSearch');
    $('#private-acts-dtb_filter input').addClass('titleSearch');

    // Title input search
    $('input.titleSearch').on('keyup', function() {
      $('#' + $(this).data('table')).DataTable()
        .columns(1)
        .search(wrapSearchWithRegExp(this.value))
        .draw();
    });

    // Filter Number input search
    $('.dataTables_filterNumber').on('keyup', function() {
      $('#' + $(this).data('table')).DataTable()
        .columns(0)
        .search(wrapSearchWithRegExp(this.value))
        .draw();
    });


    // Datatable - Filter input - close icon / reset
    $('.dataTables_filter label input').each(function() {
      var $elem = $(this);
      var $elemAfter = $elem.parent();
      $elem.bind('propertychange change click keyup input paste',
        function() {
          if ($elem.val() !== '') {
            $elemAfter.addClass('show');
          } else {
            $elemAfter.removeClass('show');
          }
        });
    });

    $('.dataTables_filter label .numberSearchX').on('click', function() {
      var $prevInput = $(this).prev('input');
      $prevInput.val('');
      if ($prevInput.hasClass('dataTables_filterNumber')) {
        $('#' + $prevInput.data('table')).DataTable()
          .search('')
          .columns(0)
          .search('')
          .draw();
      }
    });

    $('.dataTables_filter label .titleSearchX').on('click', function(e) {
      e.preventDefault();
      $('#titleSearch').val('');
      $('#' + $(this).prev('input').data('table')).DataTable()
        .search('')
        .columns(1)
        .search('')
        .draw();
    });
  }

  return {
    initialize: initialize
  };
});
