define('search',['bootstrap', 'search-module'], function($){
  'use strict';

  if ($('body').is('.index') || $('body').is('.results-page')){
    $('.search-toggle').addClass('open');
  }

  var $toggleTargets = $('.search-toggle');

  $('.search-toggle-btn').on('click', function(event){
    event.stopPropagation();

    $(this).toggleClass('open');
    $toggleTargets.toggleClass('open');
  });

  if($(window).width() < 768){
    $('.search-btn>a').trigger('click');
  }

  (function ($){
    var
      STORAGE_KEY = 'results-refined-message-hidden',
      stateIsHidden = sessionStorage.getItem(STORAGE_KEY);

    if (stateIsHidden){
      return;
    }

    $('#results-refined-message')
      .removeClass('hide')
      .on('click', function (){
        // Persist the user decision to hide the message
        sessionStorage.setItem(STORAGE_KEY, true);
      });
  }($));

  /*(function searchTrigger(){
    var
      searchQuery = getParam('q'),
      match,
      matches;

    // Let's examine the query string to see if we need to run a search
    // in the document
    if (searchQuery){
      match = new Match({
          HIGHLIGHT_CLASS: 'search-highlight'
      });

      matches = match.search(searchQuery, $('td'), {
          wholeWords: true
      });
    }
  }());*/

  return {};
});

