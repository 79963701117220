define('feedback',['jquery'], function($){
  'use strict';
  var a,
      b,
      c,
      submitContent,
      captcha,
      locked,
      validSubmit = false,
      timeoutHandle;

  // Generating a simple sum (a + b) to make with a result (c)
  function generateCaptcha(){
    a = Math.ceil(Math.random() * 10);
    b = Math.ceil(Math.random() * 10);
    c = a + b;
    submitContent = '<span>' + a + '</span> + <span>' + b + '</span>' +
      ' = <input class="submit---input" type="text" maxlength="2" size="2" required />';

    $('.feedback .submit--generated').html(submitContent);

    init();
  }


  // Check the value 'c' and the input value.
  function checkCaptcha(){
    if(captcha === c){
      // Pop the green valid icon
      $('.feedback .submit--generated')
        .removeClass('unvalid')
        .addClass('valid');
      $('.submit-form').removeClass('overlay');
      $('.submit-form-overlay').fadeOut('fast');

      $('.submit--error').addClass('hide');
      $('.submit--errorEmpty').addClass('hide');
      validSubmit = true;
    }
    else {
      if(captcha === ''){
        $('.submit--error').addClass('hide');
        $('.submit--errorEmpty').removeClass('hide');
      }
      else {
        $('.submit--error').removeClass('hide');
        $('.submit--errorEmpty').addClass('hide');
      }
      // Pop the red unvalid icon
      $('.feedback .submit--generated')
        .removeClass('valid')
        .addClass('unvalid');
      $('.submit-form').addClass('overlay');
      $('.submit-form-overlay').fadeIn('fast');
      validSubmit = false;
    }
    return validSubmit;
  }


  // Success - Submit is pushed
  function submitted(){
    // 1. FadeOut the mainContent
    $('.form-fields').addClass('fadeOut');
    // 2. Scroll to 'top'
    $('html, body').animate({
      scrollTop: 0
    }, 400, function(){
      // 3. FadeIn 'success' div / message
      $('.form-success').removeClass('hide');
      setTimeout(function(){
        $('.form-fields').addClass('hide');
        $('.form-success').addClass('fadeIn');
      },200);
    });
  }


  // Prevent spam click
  function unlock(){
    locked = false;
  }


  // init the action handlers - mostly useful when 'c' is refreshed
  function init(){
    $('.form-fields form').on('submit', function(e){
      e.preventDefault();
      if($('.feedback .submit--generated').hasClass('valid')){
        // var formValues = [];
        captcha = $('.submit---input').val();
        if(captcha !== ''){
          captcha = Number(captcha);
        }

        checkCaptcha();

        if(validSubmit === true){
          validSubmit = false;

          // TODO: Send data to a script (i.e 'feedback.php')
          // -> define the 'action' in the HTML form
          /*
            formValues = [
              $('input[name="q1-howFound"]:checked').val(),
              $('input[name="q2-why"]:checked').val(),
              $('input[name="q3-howUseful"]:checked').val(),
              $('input[name="q4-rateUI"]:checked').val(),
              $('#q5--comment').val()
            ];

            $.ajax({
                url: $(this).attr('action'),
                type: $(this).attr('method'),
                data: {
                  q1: formValues[0],
                  q2: formValues[1],
                  q3: formValues[2],
                  q4: formValues[3],
                  q5: formValues[4]
                },
                success: function(html) {
                  submitted();
                }
            });
          */

          // Temporary direct 'success' simulation
          submitted();
        }

      }
      else {
        return false;
      }
    });


    // Captcha input result handler
    $('.submit---input').on('propertychange change keyup input paste', function(){
      // Prevent the execution on the first number of the string if it's a 'multiple number string'
      // (i.e: execution on the '1' of '12')
      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function(){
        captcha = $('.submit---input').val();
        if(captcha !== ''){
          captcha = Number(captcha);
        }
        checkCaptcha();
      },150);
    });


    // Add the ':active' state CSS when 'enter' is pressed
    $('body')
      .on('keydown', function(e) {
        if (e.which === 13) {
          if($('.submit-form').hasClass('overlay')){
            checkCaptcha();
          } else {
            $('.submit-form').addClass('enter-press');
          }
        }
      })
      .on('keyup', function(e){
        if (e.which === 13) {
          $('.submit-form').removeClass('enter-press');
        }
      });


    // Refresh button click - Reset the captcha
    $('.submit-control i.fa-refresh').on('click', function(){
      if (!locked) {
        locked = true;
        setTimeout(unlock, 500);
        generateCaptcha();
        setTimeout(checkCaptcha, 0);
      }
    });


    // Submit white overlay click
    $('.submit-form-overlay').on('click', function(){
      checkCaptcha();
    });
  }

  generateCaptcha();
});
