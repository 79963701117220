define('cookies',['jquery'], function($){
  'use strict';

  var
    $cookieNotice = $('#cookie-notice'),
    STORAGE_KEY = 'cookies-agreed-state',
    hasAgreed = sessionStorage.getItem(STORAGE_KEY);

  if($cookieNotice.length === 0){
    return;
  }

  if(hasAgreed){
    return;
  }

  $cookieNotice
    .removeClass('hide')
    .find('button.close').on('click', function(){
      sessionStorage.setItem(STORAGE_KEY, true);
    });

  return {};
});

