/*
This script is used to toggle between EN & GA on the EISB site.
*/
define('toggle',['jquery', 'js-cookie'], function($, Cookies) {
  'use strict';

  /* Show or hide the language divs based on the value stored in cookie
   * Defaults to english if no cookie exists
   */
  var language = Cookies.get('eisb_lang');


  if (!language || language === 'English') {
    Cookies.set('eisb_lang', 'English');
    $('.english_language').show();
    $('.irish_language').hide();
  } else {
    $('.english_language').hide();
    $('.irish_language').show();

    // Recent legislation on homepage
    var act_tab = $('a[href="#tab-acts"]');
    var stat_tab = $('a[href="#tab-statutory"]');
    act_tab.text('Achtanna');
    stat_tab.text('Ionstraimí Reachtúla');

    // Enter other year dropdowns
    $('#js-datatables-year-filter > option:eq(0)').text('Iontráil Bliain Eile');
    $('#js-datatables-year-filter').trigger('change.select2');

    //Advanced Search placeholder
    $('#title.form-control').attr('placeholder', 'Cuardaigh an teideal amháin');
    $('#title.form-control').attr('placeholder', 'Cuardaigh an teideal amháin');
    $('#body.form-control').attr('placeholder', 'Cuardaigh an teideal agus an téacs ');
    $('#longtitle.form-control').attr('placeholder', 'Cuardaigh an teideal fada amháin');
    $('#number.form-control').attr('placeholder', 'Uimhir Achta / IR');
    $('#secNum.form-control').attr('placeholder', 'Uimhir Ailt');
  }

  /* Toggles the various language divs when user clicks the language link */
  $('.language_toggle').on('click', function() {
    var chosenLanguage = $(this).text();
    var act_tab = $('a[href="#tab-acts"]');
    var stat_tab = $('a[href="#tab-statutory"]');
    if (chosenLanguage === 'Gaeilge') {
      $('.english_language').hide();
      $('.irish_language').show();

      // Recent legislation on homepage
      act_tab.text('Achtanna');
      stat_tab.text('Ionstraimí Reachtúla');

      // Enter other year dropdowns
      $('#js-datatables-year-filter > option:eq(0)').text('Iontráil Bliain Eile');
      $('#js-datatables-year-filter').trigger('change.select2');

      //Advanced Search placeholder
      $('#title.form-control').attr('placeholder', 'Cuardaigh an teideal amháin');
      $('#body.form-control').attr('placeholder', 'Cuardaigh an teideal agus an téacs ');
      $('#longtitle.form-control').attr('placeholder', 'Cuardaigh an teideal fada amháin');
      $('#number.form-control').attr('placeholder', 'Uimhir Achta / IR');
      $('#secNum.form-control').attr('placeholder', 'Uimhir Ailt');

    } else if (chosenLanguage === 'English') {
      $('.english_language').show();
      $('.irish_language').hide();

      // Recent legislation on homepage
      act_tab.text('Acts');
      stat_tab.text('Statutory Instruments');
      // Enter other year dropdowns
      $('#js-datatables-year-filter > option:eq(0)').text('Enter Other Year');
      $('#js-datatables-year-filter').trigger('change.select2');

      //Advanced Search placeholder
      $('#title.form-control').attr('placeholder', 'Search title only');
      $('#body.form-control').attr('placeholder', 'Search title and text');
      $('#longtitle.form-control').attr('placeholder', 'Search long title only');
      $('#number.form-control').attr('placeholder', 'Act / SI Number');
      $('#secNum.form-control').attr('placeholder', 'Section number');
    }
    Cookies.set('eisb_lang', chosenLanguage);
  });
});
