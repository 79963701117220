define('dropdown',['jquery','bootstrap'], function($){
  'use strict';

  var $dropdownElements = $('.search-toggle, .mobile-nav-action, .navigation-bar');

  function mobileMenu(width){
    if(width < 769){
      $dropdownElements.removeClass('open');
    }
  }


  // Initialise Bootstrap Dropdown
  $('[data-toggle="dropdown"]').dropdown();

  $(window).on('load resize', function() {
    var windowWidth = window.innerWidth ||
                      document.documentElement.clientWidth ||
                      document.body.clientWidth;
    mobileMenu(windowWidth);
  });


  $('[data-toggle="dropdown-remain"]').on('click', function(evt){
    var
      $self = $(this),
      $tohide = $($self.attr('data-hide')).not(this),
      $target = $($self.attr('data-target'));

    evt.preventDefault();

    if($('.search-btn').parent().hasClass('open')){
      $('.search-btn').attr('title', 'Show the search panel');
    } else {
      $('.search-btn').attr('title', 'Hide the search panel');
    }

    $tohide.each(function(index, elem){
      var target = $(elem).attr('data-target');

      $(elem).parent().removeClass('open');
      $(target).removeClass('open');
    });

    $self.parent().toggleClass('open');
    $target.toggleClass('open');
  });

});
