define('datatable-plugins/datatable-stick-menu',['jquery', 'datatables'], function($){
  'use strict';
  
  // Sticker menu + active state on scroll
  function datatableStickMenu(){
    var lastId,
        $topMenu = $('.datatable-breadcrumb'),
        topMenuHeight = $topMenu.outerHeight() + 80,
        // All list items
        $menuItems = $topMenu.find('a'),
        // Anchors corresponding to menu items
        scrollItems = $menuItems.map(function(){
          var $item = $($(this).attr('href'));
          if ($item.length) { return $item; }
        });

    // Scroll animation on click
    $menuItems.on('click', function(e){
      var href = $(this).attr('href'),
          offsetTop = href === '#' ? 0 : $(href).offset().top - topMenuHeight;
      $('html, body').stop().animate({
        scrollTop: offsetTop
      }, 400);
      e.preventDefault();
    });

    $(window).scroll(function(){
      // Container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;

      // Id of current scroll item
      var cur = scrollItems.map(function(){
        if ($(this).offset().top < fromTop){
          return this;
        }
      });
      // Get the id of the current element
      cur = cur[cur.length - 1];
      var id = cur && cur.length ? cur[0].id : '';

      if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        $menuItems
          .parent()
          .removeClass('active')
          .end()
          .filter('[href=#' + id + ']')
          .parent()
          .addClass('active');
      }
    });
  }
  if($('.main-content').hasClass('datatable-multi')){
    datatableStickMenu();
  }

});
