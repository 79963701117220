define('home-image',['jquery'], function($){
  'use strict';

  var
    $image = $('.homepage-alt-image'),
    IMAGES_PATH = 'static/images/homepage/',
    IMAGES_EXTENSION = '.png',
    // Specify the range of avalable images between MIN and MAX The image file
    // names should be: 1.png, 2.png, and so on
    MIN = 1,
    MAX = 2,
    rnd;

    if(!$image.length){
      return;
    }

    // Generates a random number between MIN and MAX inclusive
    rnd = Math.floor(Math.random() * (1 + MAX - MIN) + MIN);
    $image.attr('src', IMAGES_PATH + rnd + IMAGES_EXTENSION);
});

