define('navbar',['jquery'], function($){
  'use strict';

  // Navigation - header template highlight button
  var pageName = $('.main-content').data('highlight');
  if(pageName){
  	$('.navigation-bar .nav li[data-nav="' + pageName + '"]').addClass('active');
  }
});

